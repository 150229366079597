<template>
  <div v-loading="loading">
    <div class="tg_menu_ber_boolong_nsx">
      <div class="container">
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push({name: 'calendar'})">Booking calendar</a>
          </li>
          <li>
            <a href="#">New booking</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <ValidationObserver ref="form">
        <form @submit.prevent="login">
          <div class="tg_new_booking_nsx">
            <div class="tg_text_new_booking_ft">
              <h3>予約番号：{{this.order.booking_id}}</h3>
              <p>お客様情報</p>
              <ul>
                <li>
                  <label>
                    お名前
                    <span>*</span>
                  </label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max:50"
                    name="お名前"
                  >
                  <div class="textwidget">
                    <input v-model.trim="customer.name" ref="customer_name" type="text"/>
                    <i v-if="errors[0]" class="tg_sos_nsx">{{ errors[0] }}</i>
                  </div>
                  </ValidationProvider>
                </li>
                <li>
                  <label>
                    電話番号
                    <span>*</span>
                  </label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|phoneRegex|max:16"
                    name="電話番号"
                  >
                  <div class="textwidget">
                    <input type="text" ref="customer_phone" v-model.trim="customer.phone" @blur="customer.phone = fullSizeToHalfSize(customer.phone)"/>
                    <i v-if="errors[0]" class="tg_sos_nsx">{{ errors[0] }}</i>
                  </div>
                  </ValidationProvider>
                </li>
                <li>
                  <label>
                    メールアドレス
                    <span>*</span>
                  </label>
                   <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|emailRegex|max:100"
                    name="メールアドレス"
                  >
                  <div class="textwidget">
                    <input type="text" ref="customer_email" v-model.trim="customer.email"/>
                    <i v-if="errors[0]" class="tg_sos_nsx">{{ errors[0] }}</i>
                  </div>
                  </ValidationProvider>
                </li>
              </ul>
            </div>
            <div class="tg_text_new_booking_ft tg_text_new_booking_ver2_ft">
              <p>予約情報</p>
              <ul>
                <li>
                  <label>
                    利用目的
                    <span>*</span>
                  </label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max:200"
                    name="利用目的"
                  >
                  <div class="textwidget">
                    <input type="text" name v-model.trim="order.description" ref="description" placeholder="出演、リハーサル、カルチャー教室等" />
                    <i v-if="errors[0]" class="tg_sos_nsx">{{ errors[0] }}</i>
                  </div>
                  </ValidationProvider>
                </li>
                <li class="text_scheduled">
                  <label>
                    利用予定人員
                    <span>*</span>
                  </label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|numberRegex|maxLength:200"
                    name="利用予定人員"
                  >
                  <div class="textwidget">
                    <input type="text"  v-model.trim="order.people_number" ref="people_number" @blur="order.people_number = fullSizeToHalfSize(order.people_number)"/>
                    <p>人</p>
                    <i v-if="errors[0]" class="tg_sos_nsx">{{ errors[0] }}</i>
                  </div>
                  </ValidationProvider>
                </li>
                <li>
                  <label>
                    利用日時
                    <span>*</span>
                  </label>
                  <div class="text_date_time_ft">
                    <ul class="tg_time_nd">
                      <li>
                        <div class="textwidget" ref="div-date">
                          <figure id="tg_date_choose" name class="flatpickr-input">
                            <img :src="urlImage()" @click="addClassCss()"/>
                          </figure>
                          <p>{{convertDate(this.date)}}</p>
                          <ValidationProvider rules="required|checkDateAdd7Day" vid="date" v-slot="{ errors }">
                          <input type="hidden" name="date" ref="date" v-model="date"/>
                          <i class="tg_sos_nsx">{{ errors[0] }}</i>
                          </ValidationProvider>
                        </div>
                      </li>
                      <li>
                        <div class="textwidget" ref="div-time">
                          <ValidationProvider name="start" rules="" vid="start">
                          <input type="hidden" name="start" ref="start" v-model="startTime"/>
                          <select @change="onChangeStartTime($event)">
                            <option v-for="(v, k) in getArrTimeFirst" :key="k" :value="v" :selected="v == startTime">{{v}}</option>
                          </select>
                          <!-- <p>{{ errors[0] }}</p> -->
                          </ValidationProvider>
                          <p>~</p>
                          <ValidationProvider name="end" rules="required|checkHours:@start" vid="end" v-slot="{ errors }">
                          <input type="hidden" name="end" ref="end" v-model="endTime"/>
                          <select @change="onChangeEndTime($event)">
                            <option v-for="(v, k) in getArrTimeLast" :key="k" :value="v" :selected="v == endTime">{{v}}</option>
                          </select>
                          <i class="tg_sos_nsx">{{ errors[0] }}</i>
                        </ValidationProvider>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tg_price_booking_nsx tg_border_bottom_nsx">
              <p>
                <span>利用料金</span>
                <strong>{{formatNumber(this.priceRoom)}}円</strong>
              </p>
            </div>
            <div class="tab_content_booking_ver4_hd">
              <div class="text_tab_ver4_hd">
                <p>スタッフ/Staff</p>
                <div class="arrdown" v-bind:class="[!toggleStaff ? 'arrdown_ver4' : 'arrup_ver4' ]">
                  <i class="fa fa-plus" aria-hidden="true" @click="changeToggleStaff(true)"></i>
                </div>
                <div class="arrup" v-bind:class="[!toggleStaff ? 'arrup_ver4' : 'arrdown_ver4' ]">
                  <i class="fa fa-minus" aria-hidden="true" @click="changeToggleStaff(false)"></i>
                </div>
              </div>
              <span>※スタッフが選択されていない場合、ご利用当日に対応できませんのでご注意ください。</span>
              <div v-show="this.toggleStaff == true">
                <div class="botton_line_ver4" v-for="(item, idx) in this.staff" :key="idx">
                  <div class="textwidget">
                    <div class="tg_left_booking_nsx">
                      <div class="text_left_tab_ft">
                        <p>{{item.name}}</p>
                        <h5>{{formatNumber(item.price)}} 円</h5>
                      </div>
                      <div class="text_right_tab_ft">
                        <p>
                          <span>数量：</span>
                          <strong>{{item.quantity}}</strong>
                        </p>
                        <ul>
                          <li>
                            <a href="javascript:void(0)" @click="handleItem(idx, 'minus')">-</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">{{item.us_quantity}}</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" @click="handleItem(idx, 'plus')">+</a>
                          </li>
                        </ul>
                      </div>
                      <div class="arrup arrup_ver5">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tg_price_booking_nsx tg_border_bottom_nsx">
              <p>
                <span>スタッフ料金</span>
                <strong>{{formatNumber(this.priceStaff)}} 円</strong>
              </p>
            </div>
            <div class="tab_content_booking_ver3_hd">
              <div class="text_tab_ver3_hd">
                <p>機材レンタル</p>
                <div
                  class="arrdown"
                  v-bind:class="[!toggleEquiment ? 'arrdown_ver3' : 'arrup_ver3' ]"
                >
                  <i class="fa fa-plus" aria-hidden="true" @click="changeToggleEquipment(true)"></i>
                </div>
                <div
                  class="arrup"
                  v-bind:class="[!toggleEquiment ? 'arrup_ver3' : 'arrdown_ver3' ]"
                >
                  <i class="fa fa-minus" aria-hidden="true" @click="changeToggleEquipment(false)"></i>
                </div>
              </div>
              <div v-show="this.toggleEquiment == true">
                <div class="tab_content_booking_nsx">
                  <div
                    class="tab_content_booking_ver2_nd"
                    v-for="(item, idx) in this.equipment"
                    :key="idx"
                  >
                    <div class="tab_click_ver2_nd">
                      <p>{{idx}}</p>
                      <div
                        class="arrdown"
                        v-bind:class="[!item[0].toggleChild ? 'arrdown_ver2' : 'arrup_ver2' ]"
                      >
                        <i
                          class="fa fa-plus"
                          aria-hidden="true"
                          @click="changeToggleChild(true, idx)"
                        ></i>
                      </div>
                      <div
                        class="arrup"
                        v-bind:class="[!item[0].toggleChild ? 'arrup_ver2' : 'arrdown_ver2' ]"
                      >
                        <i
                          class="fa fa-minus"
                          aria-hidden="true"
                          @click="changeToggleChild(false, idx)"
                        ></i>
                      </div>
                    </div>
                    <div v-show="item[0].toggleChild == true">
                      <div class="question_answer_fe">
                        <div class="botton_line" v-for="(value, key) in item" :key="key">
                          <div class="textwidget">
                            <div class="tg_left_booking_nsx">
                              <div class="text_left_tab_ft">
                                <p>{{value.name}}</p>
                                <h5>{{formatNumber(value.price)}} 円</h5>
                              </div>
                              <div class="text_right_tab_ft">
                                <p>
                                  <span>数量：</span>
                                  <strong>{{value.quantity}}</strong>
                                </p>
                                <ul>
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click="handleItemEquipment(key, 'minus', idx)"
                                    >-</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)">{{value.us_quantity}}</a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click="handleItemEquipment(key, 'plus', idx)"
                                    >+</a>
                                  </li>
                                </ul>
                              </div>
                              <div
                                class="arrdown"
                                v-bind:class="[value['description'].length && !value['description'][0].toggleDescription ? 'arrdown_ver1' : 'arrup_ver1' ]"
                              >
                                <i
                                  class="fa fa-plus"
                                  aria-hidden="true"
                                  @click="changeToggleDescription(true, key, idx)"
                                ></i>
                              </div>
                              <div
                                class="arrup"
                                v-bind:class="[value['description'].length && !value['description'][0].toggleDescription ? 'arrup_ver1' : 'arrdown_ver1' ]"
                              >
                                <i
                                  class="fa fa-minus"
                                  aria-hidden="true"
                                  @click="changeToggleDescription(false, key, idx)"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div v-show="value['description'].length && value['description'][0].toggleDescription == true">
                            <div class="expand">
                              <div
                                class="tg_left_booking_nsx"
                                v-for="(v, k) in value['description']"
                                :key="k"
                              >
                                <div class="text_left_tab_ft">
                                  <p>{{v.equipment_name}}</p>
                                </div>
                                <div class="text_right_tab_ft">
                                  <p>
                                    <span>数量：</span>
                                    <strong>{{v.equipment_quantity}}</strong>
                                  </p>
                                  <ul>
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        @click="handleItemEquipmentDescription(key, 'minus', idx, k)"
                                      >-</a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0)">{{v.us_quantity}}</a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        @click="handleItemEquipmentDescription(key, 'plus', idx, k)"
                                      >+</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tg_price_booking_nsx">
              <p>
                <span>機材レンタル料金</span>
                <strong>{{formatNumber(this.priceEquiment)}}円</strong>
              </p>
            </div>

            <div class="tg_total_price_nsx">
              <div class="text_total_price_ft">
                <p>
                  <span>合計</span>
                  <strong>{{formatNumber(this.priceRoom + this.priceStaff + this.priceEquiment)}}円</strong>
                </p>
              </div>
              <div class="bt_price_total_nsx">
                <a href="javascript:void(0)" @click="$router.push({name: 'calendar'})">閉じる</a>
                <!-- <a href="javascript:void(0)">確認</a> -->
                <button class="btn-submit" type="submit">確認 <span class="icon-tick">&nbsp;</span></button>
              </div>
              <div class="text_note_price_nsx">
                <p>※ご利用当日ではスタッフを手配することが出来ません。必ずスタッフを選択してください。</p>
                <p>※ご決済を15分過ぎた場合は、自動的にキャンセルになりますので、予めご了承ください</p>
                <p>※キャンセル料、期限は以下の通りです。</p>
                <ul>
                  <li>30日～15日 30％</li>
                  <li>14日～8日 50％</li>
                  <li>7日前～当日 100％</li>
                </ul>
                <p>キャンセル後、30日以内に返金を振込させていただきます。</p>
              </div>
            </div>
          </div>

        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import flatpickr from "flatpickr";
import { ENV } from "@/core/config/env";
import { GET_EQUIPMENT, CREATE_BOOKING, CHECK_TIME_BOOKING, PREVIEW_BOOKING } from "@/core/services/store/booking.module";
import { STATUS_CODE, STAFF_EQUIPMENT } from "@/core/config/constant";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS, BAD_REQUEST } = STATUS_CODE;
const { STAFF, EQUIPMENT } = STAFF_EQUIPMENT;

export default {
  name: "Booking",
  data() {
    return {
      loading: false,
      data: {},
      staff: [],
      equipment: [],
      toggleStaff: false,
      toggleEquiment: false,
      priceRoom: 0,
      priceStaff: 0,
      priceEquiment: 0,
      priceTotal: 0,
      arrTime: [
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00"
      ],
      arrTimeFirst: [],
      arrTimeLast: [],
      startTime: "",
      endTime: "",
      roomPrice: 0,
      date: "",
      customer: {
        name: "",
        email: "",
        phone: ""
      },
      order: {
        people_number: "",
        description: "",
        booking_id: "",
      },
      order_staff: [],
      order_equipment: []
    };
  },

  watch: {},

  computed: {
    getArrTimeFirst() {
      let arrTimeFirst = this.arrTime.filter(function(f) {
        return f !== "16:00";
      });

      return arrTimeFirst;
    },

    getArrTimeLast() {
      let arrTimeLast = this.arrTime.filter(function(f) {
        return f !== "10:00" && f !== "10:30" ;
      });

      return arrTimeLast;
    }
  },

  mounted() {
    flatpickr("#tg_date_choose", {
      defaultDate: "today",
      dateFormat: "Y/m/d",
      disableMobile: true,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["日", "月", "火", "水", "木", "金", "土"]
        },
        months: {
          shorthand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月"
          ],
          longhand: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ]
        }
      },
      plugins: [
        ShortcutButtonsPlugin({
          button: [
          {
              label: "Yesterday"
          },
          {
              label: "今日"
          },
          {
              label: "Tomorrow"
          }
          ],
          label: "",
          onClick: (index, fp) => {
            switch (index) {
              case 0:
              this.date = new Date(Date.now() - 24 * 60 * 60 * 1000);
              break;
              case 1:
              this.date = moment().format("YYYY-MM-DD");
              break;
              case 2:
              this.date = new Date(Date.now() + 24 * 60 * 60 * 1000);
              break;
            }
            fp.setDate(this.date);
          }
        })
      ],
      minDate: "today",
      onChange: (selectedDates, dateStr, instance) => {
        this.date = moment(dateStr, "YYYY/MM/DD").format("YYYY-MM-DD");
      }
    });
  },

  async created() {
    let paramsRoute = this.$route.params;
    if (paramsRoute && paramsRoute.back == 'preview') {
      let data_order = JSON.parse(localStorage.getItem('order'));
      if (data_order) {
        this.order = data_order.order;
        this.booking_id = data_order.order.booking_id;
        this.date = data_order.order.date;
        this.startTime = data_order.order.start_time;
        this.endTime = data_order.order.end_time;
        this.customer = data_order.customer;
        this.staff = data_order.staff;
        this.equipment = data_order.equipment;
        this.priceRoom = data_order.order.price_room;
        this.priceStaff = data_order.order.price_staff;
        this.priceEquiment = data_order.order.price_equipment;
        this.toggleStaff = data_order.order_staff.length ? true : false;
        this.toggleEquiment = data_order.order_equipment.length ? true : false;
      }
    } else {
      this.order.booking_id = this.makeid();
      this.date = paramsRoute.date ? moment(paramsRoute.date, 'YYYY-MM-DD').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
      this.startTime = paramsRoute.time ? paramsRoute.time :"10:00";
      this.endTime = paramsRoute.time ? moment(paramsRoute.time, 'HH:mm').add(1, 'hours').format("HH:mm") : "11:00";
      await this.getDataStaff();
      await this.getDataEquipment();
      this.priceRoom = this.calculateRoomPrice();
    }
  },

  methods: {
    urlImage() {
      return `${ENV.PREFIX_FOLDER}/template/images/big-calendar-icon.png`;
    },

    addClassCss() {
      setTimeout(function () {
        let value = document.getElementsByClassName('arrowTop')[0].children[0].children[1].children[0].children[1].children[0].value
        document.getElementsByClassName('arrowTop')[0].children[0].children[1].children[0].children[1].insertAdjacentHTML('beforeend', "<div style='position: absolute;top: 3px;left: 55px;'>年</div>")
        document.getElementsByClassName('arrowTop')[0].children[0].children[1].children[0].children[1].children[0].value = value
      }, 100)
    },

    async getDataStaff() {
      this.loading = true;
      let start = moment(this.startTime, "HH:mm");
      let end = moment(this.endTime, "HH:mm");
      let duration = moment.duration(end.diff(start));
      let hours = duration.asHours();
      let params = this.serialize({
        type: STAFF,
        time: hours
      });
      let data = await this.$store.dispatch(GET_EQUIPMENT, params);
      if (data.code == SUCCESS) {
        this.staff = data.data.list_equipment;
        this.roomPrice = data.data.room_price;
      }
      this.loading = false;
    },

    async getDataEquipment() {
      this.loading = true;
      let start = moment(this.startTime, "HH:mm");
      let end = moment(this.endTime, "HH:mm");
      let duration = moment.duration(end.diff(start));
      let hours = duration.asHours();
      let params = this.serialize({
        type: EQUIPMENT,
        time: hours
      });
      let data = await this.$store.dispatch(GET_EQUIPMENT, params);
      if (data.code == SUCCESS) {
        this.equipment = data.data.list_equipment;
        this.roomPrice = data.data.room_price;
      }
      this.loading = false;
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    handleItem(index, format) {
      if (format == "plus") {
        if (this.staff[index].us_quantity < this.staff[index].quantity) {
          this.staff[index].us_quantity += 1;
          let amount = 0;
          this.staff.map(
            element => (amount += element.price * element.us_quantity)
          );
          this.priceStaff = amount;
        }
      } else if (format == "minus") {
        if (this.staff[index].us_quantity > 0) {
          this.staff[index].us_quantity -= 1;
          let amount = 0;
          this.staff.map(
            element => (amount += element.price * element.us_quantity)
          );
          this.priceStaff = amount;
        }
      }
    },

    handleItemEquipment(index, format, key) {
      if (format == "plus") {
        if (this.equipment[key][index].us_quantity < this.equipment[key][index].quantity) {
          this.equipment[key][index].us_quantity += 1;
          let amount = 0;
          for (const [key, value] of Object.entries(this.equipment)) {
            for (const [index, item] of Object.entries(value)) {
              amount += item.price * item.us_quantity;
            }
          }
          this.priceEquiment = amount;

          for (const [key, value] of Object.entries(this.equipment[key][index]['description'])) {
            value.us_quantity = value.equipment_quantity
          }
        }
      } else {
        if (this.equipment[key][index].us_quantity > 0) {
          this.equipment[key][index].us_quantity -= 1;
          let amount = 0;
          for (const [key, value] of Object.entries(this.equipment)) {
            for (const [index, item] of Object.entries(value)) {
              amount += item.price * item.us_quantity;
            }
          }
          this.priceEquiment = amount;

          if (this.equipment[key][index].us_quantity == 0) {
            for (const [key, value] of Object.entries(this.equipment[key][index]['description'])) {
              value.us_quantity = 0
            }
          }
        }
      }
    },

    handleItemEquipmentDescription(index, format, key, k) {
      if (format == "plus") {
        if (this.equipment[key][index]["description"][k].us_quantity < this.equipment[key][index]["description"][k].equipment_quantity) {
          this.equipment[key][index]["description"][k].us_quantity += 1;
          if (this.equipment[key][index].us_quantity == 0) {
            this.equipment[key][index].us_quantity += 1;
            let amount = 0;
            for (const [key, value] of Object.entries(this.equipment)) {
              for (const [index, item] of Object.entries(value)) {
                amount += item.price * item.us_quantity;
              }
            }
            this.priceEquiment = amount;
          }
        }
      } else {
        if (this.equipment[key][index]["description"][k].us_quantity > 0 ) {
          this.equipment[key][index]["description"][k].us_quantity -= 1;
          if (this.equipment[key][index]["description"][k].us_quantity == 0) {
            let n = 0
            let length = this.equipment[key][index]["description"].length;
            let dataEquipment = this.equipment[key][index];
            for (const [key, value] of Object.entries(this.equipment[key][index]["description"])) {
              n += 1;

              if (value.us_quantity == 0) {
                if (n == length) {
                  dataEquipment.us_quantity = 0;
                }
                continue;
              } else {
                break;
              }
            }

            let amount = 0;
            for (const [key, value] of Object.entries(this.equipment)) {
              for (const [index, item] of Object.entries(value)) {
                amount += item.price * item.us_quantity;
              }
            }
            this.priceEquiment = amount;
          }
        }
      }
    },

    onChangeStartTime(event) {
      this.startTime = event.target.value;
      if (this.startTime < this.endTime) {
        this.priceRoom = this.calculateRoomPrice();
        this.getDataStaff();
        this.getDataEquipment();
      }
    },

    onChangeEndTime(event) {
      this.endTime = event.target.value;
      if (this.startTime < this.endTime) {
        this.priceRoom = this.calculateRoomPrice();
        this.getDataStaff();
        this.getDataEquipment();
      }
    },

    convertDate(date) {
      let arrDate = date.split("-");
      return arrDate[0] + " 年 " + arrDate[1] + " 月 " + arrDate[2] + "日";
    },

    login() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          let params = {
            date: this.date,
            start_time: this.startTime,
            end_time: this.endTime,
          };
          let data = await this.$store.dispatch(CHECK_TIME_BOOKING, params);
          if (data.code == SUCCESS) {
              this.staff.forEach((value, key) => {
                if (value.us_quantity != 0) {
                  this.order_staff.push(value);
                }
              });

              for (const [key, value] of Object.entries(this.equipment)) {
                for (const [k, v] of Object.entries(value)) {
                  if (v.us_quantity != 0 ) {
                    this.order_equipment.push(v);
                  }
                }
              }

              let paramsOrder = {
                customer: this.customer,
                order: {
                  booking_id: this.order.booking_id,
                  date: this.date,
                  start_time: this.startTime,
                  end_time: this.endTime,
                  description: this.order.description,
                  people_number: this.order.people_number,
                  price_room: this.priceRoom,
                  price_staff: this.priceStaff,
                  price_equipment: this.priceEquiment
                },
                staff: this.staff,
                equipment: this.equipment,
                order_staff: this.order_staff,
                order_equipment: this.order_equipment,
              }

              this.$store.dispatch(PREVIEW_BOOKING, paramsOrder);
              this.$router.push({name: 'booking-detail'});
          } else if (data.code == BAD_REQUEST) {
            this.$refs.form.setErrors(
              {
                'end': [this.$t('ER_01')]
              }
            )
            this.$refs['div-time'].scrollIntoView();
          } else {

          }
          this.loading = false;
        } else {
          for (const [key, value] of Object.entries(this.$refs.form.errors)) {
            if (value.length) {
              if (key == 'お名前') {
                this.$refs.customer_name.focus();
                break;
              } else if (key == '電話番号') {
                this.$refs.customer_phone.focus();
                break;
              } else if (key == 'メールアドレス') {
                this.$refs.customer_email.focus();
                break;
              } else if (key == '利用目的') {
                this.$refs.description.focus();
                break;
              } else if (key == '利用予定人員') {
                this.$refs.people_number.focus();
                break;
              } else if (key == 'date') {
                this.$refs['div-date'].scrollIntoView();
                break;
              } else if (key == 'end') {
                this.$refs['div-time'].scrollIntoView();
                break;
              }
            }
          }
        }
      });
    },

    makeid() {
        var timestamp = moment().format("X");
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' + timestamp;
        var charactersLength = characters.length;
        for ( var i = 0; i < 8; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },

    calculateRoomPrice() {
      let start = moment(this.startTime, "HH:mm");
      let end = moment(this.endTime, "HH:mm");
      let duration = moment.duration(end.diff(start));
      let hours = duration.asHours();
      let price = hours * this.roomPrice;
      return price;
    },

    fullSizeToHalfSize(chars) {
        var ascii = '';
        for(var i=0, l=chars.length; i<l; i++) {
            var c = chars[i].charCodeAt(0);

            // make sure we only convert half-full width char
            if (c >= 0xFF00 && c <= 0xFFEF) {
              c = 0xFF & (c + 0x20);
            }

            ascii += String.fromCharCode(c);
        }

        return ascii;
    },

    changeToggleStaff(bln) {
      if (bln == true) {
        this.toggleStaff = true;
        this.toggleEquiment = false;
      } else {
        this.toggleStaff = false;
      }
    },

    changeToggleEquipment(bln) {
      if (bln == true) {
        this.toggleEquiment = true;
        this.toggleStaff = false;
      } else {
        this.toggleEquiment = false;
      }
    },

    changeToggleChild(bln, index) {
      if (bln == true) {
        for (const [key, value] of Object.entries(this.equipment)) {
          if (key == index) {
            value[0].toggleChild = true;
          } else {
            value[0].toggleChild = false;
          }
        }
      } else {
        this.equipment[index][0].toggleChild = false;
      }
    },

    changeToggleDescription(bln, key, index) {
      if (bln == true) {
        for (const [k, v] of Object.entries(this.equipment[index])) {
          if (v['description'].length) {
            if (k == key) {
              v['description'][0].toggleDescription = true;
            } else {
              v['description'][0].toggleDescription = false;
            }
          }
        }
      } else {
        if (this.equipment[index][key]['description'].length) {
          this.equipment[index][key]['description'][0].toggleDescription = false;
        }
      }
    }
  }
};
</script>
